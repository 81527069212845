<template>
    <div class="modal-lounge-request">
        <div class="title" v-html="$translate(title)" />
        <div class="body" :class="{ 'm-t-0': options.hideHeader }" v-html="body" />
        <div v-if="buttons" class="buttons-lounge-request flex-row">
            <button
                @click="onClick(btn)"
                v-html="btn.label"
                :key="idx"
                v-for="(btn, idx) in buttons"
                :class="btn.class"
            ></button>
        </div>
        <div class="request-tip" @click="onClickTip">
            <i class="tip-help material-icons">help_outline</i>
            <span class="tip-text" v-html="'유료/무료 신청 차이가 뭔가요?'"></span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalLoungeRequest',
    props: ['options'],
    mounted() {
        document.addEventListener('keydown', this.onKeydown)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onKeydown)
    },
    computed: {
        title() {
            return (this.options || {}).title
        },
        body() {
            return (this.options || {}).body
        },
        buttons() {
            return [
                {
                    label: `<span class='spoqa-f-bold'>유료</span>로 신청하기<br><span style='font-size: 13px; color: rgba(255,255,255, 0.8);'>(상대가 무료로 확인)</span>`,
                    class: ['btn-request', 'btn-primary', 'btn-top'],
                    disabled: this.disabled,
                },
                {
                    label: `<span class='spoqa-f-bold'>무료</span>로 신청하기`,
                    class: ['btn-request', 'btn-bottom'],
                    disabled: false,
                },
            ]
        },
    },
    methods: {
        onClick(btn) {
            this.$emit('close', this.buttons.indexOf(btn))
        },
        onClickTip() {
            this.$emit('close')
            this.$stackRouter.push({ name: 'LoungeRequestIntroductionPage' })
        },
    },
}
</script>

<style scoped lang="scss">
.modal-lounge-request {
    margin: 0 20px 0 20px;
    padding: 28px 16px 24px 16px;
    width: calc(100vw - 40px);
    text-align: center;
    .title {
        font-size: 20px;
        @include spoqa-f-medium;
        color: black;
    }
    .body {
        // margin: 12px 24px 0 24px;
        margin: 12px 0 0 0;
        font-size: 14px;
        color: $grey-09;
        line-height: 20px;
        @include spoqa-f-regular;
    }
    .buttons-lounge-request {
        margin: 14px 0 20px 0;
        display: flex;
        flex-flow: column;

        .btn-request {
            margin-top: 10px;
            font-weight: normal;
            border-radius: 12px;
            line-height: 20px;
            @include spoqa-f-regular;
        }
        .btn-top {
            height: 60px;
        }
        .btn-bottom {
            height: 48px;
            background-color: $grey-02;
            color: $grey-09;
            border: none;
        }
    }
    .request-tip {
        border-radius: 14px;
        border: solid 1px $grey-03;
        padding: 5px 14px 5px 12px;
        width: fit-content;
        min-width: 209px;
        margin-left: 48px;
        align-self: center;
        font-size: 13px;
        @include spoqa-f-regular;
        .tip-help {
            vertical-align: middle;
            font-size: 15px;
            margin-right: 4px;
        }
    }
}
</style>
